<template>
  <button
    @click="redirectToPDP">
    <span class="btn-text"> {{ text || defaultText }}</span>
  </button>
</template>

<script>
import { useI18n } from 'vue-i18n';
import StockSchema from 'CommonUtils/schemas/stock';

export default {
  name: 'BtnRedirectToPDP',
  props: {
    destinationUrl: {
      type: String,
      required: false,
      default: ''
    },
    text: {
      type: String,
      required: false,
      default: '',
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
    colorSelected: {
      type: Object,
      required: false,
      default: () => undefined
    },
    redirectDisabled : {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  setup(props) {
    const { t } = useI18n();
    const productUri = inject('productUri');

    const defaultText = computed(() => {
      if (props.disabled) {
        const label = StockSchema.getStatusLabel(props.colorSelected?.add_to_cart);
        if (label) return t(label);
        return t('product.sold_out');
      }
      return t('global.show_detail');
    });

    const redirectToPDP = ()  => {
      const destinationUrl =  props.destinationUrl || productUri.value;
      if (props.disabled || props.redirectDisabled) return;
      window.location.href = destinationUrl;
    };

    return {
      redirectToPDP,
      defaultText
    };
  }
};
</script>
